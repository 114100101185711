import Vue from 'vue'
import Router from 'vue-router'
import { PERMISSIONS } from '../domain_constants'
import { BOARD_ALIAS } from '../app_constants'
import store from "../store";
import { i18n } from '../i18n';

const Layout = () => import(/* webpackChunkName: "layout" */ '../components/shared/Layout.vue')
const Body = () => import(/* webpackChunkName: "body" */ '../components/shared/Body.vue')
const Home = () => import(/* webpackChunkName: "home" */ '../components/views/Home.vue')
const PWBIProject = () => import(/* webpackChunkName: "pwbi-project" */ '../components/views/pwbi/PWBIProject.vue')
const PWBI = () => import(/* webpackChunkName: "pwbi" */ '../components/views/pwbi/PWBI.vue')
const PWBIBoard = () => import(/* webpackChunkName: "pwbi-board" */ '../components/views/pwbi/PWBIBoard.vue')
const ChangePass = () => import(/* webpackChunkName: "change-pass" */ '../components/views/login/ChangePass.vue')
const UsersList = () => import(/* webpackChunkName: "users-list" */ '../components/views/users/UsersList.vue')
const UserMenuEdit = () => import(/* webpackChunkName: "user-menu-edit" */ '../components/views/users/UserMenuEdit.vue')
const ModiDevices = () => import(/* webpackChunkName: "modi-devices" */ '../components/views/modi/ModiDevices.vue')
const Modi = () => import(/* webpackChunkName: "modi" */ '../components/views/modi/Modi.vue')
const ModiGraph = () => import(/* webpackChunkName: "modi-graph" */ '../components/views/modi/ModiGraph.vue')
const ConfigView = () => import(/* webpackChunkName: "config-view" */ '../components/views/config/ConfigView.vue')
const NotFound = () => import(/* webpackChunkName: "not-found" */ '../components/shared/NotFound.vue')
const ProjectList = () => import(/* webpackChunkName: "project-list" */ '../components/views/project/ProjectList.vue')
const ProjectCreate = () => import(/* webpackChunkName: "project-create" */ '../components/views/project/ProjectCreate.vue')
const ProjectUpdate = () => import(/* webpackChunkName: "project-update" */ '../components/views/project/ProjectUpdate.vue')
const NotificationsConfig = () => import(/* webpackChunkName: "notifications-config" */ '../components/views/users/NotificationsConfig.vue')
const FilesContainer = () => import(/* webpackChunkName: "files-container" */ '../components/views/files/FilesContainer.vue')
const NPYMS = () => import(/* webpackChunkName: "npyps" */ '../components/views/custom_graphics/NPYMS.vue')

const FilesDownloader = () => import(/* webpackChunkName: "files-downloader" */ '../components/views/files/FilesDownloader.vue')

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

function findNodeByAlias(node, alias) {
  console.log(node)
  console.log(node.alias, alias)
  if (node.alias === alias) {
      return node;
  }
  if (node.submenu) {
      for (let child of node.submenu) {
          const found = findNodeByAlias(child, alias);
          if (found) {
              return found;
          }
      }
  }
  return null;
}

function withDefaultTypeAndGuard(to, from, next) {
  // Redirige a `/files/instrument` si `type` está ausente
  if (!to.params.type) {
    next({ name: 'FilesContainer', params: { type: 'instrument' } });
  } else {
    // Ejecuta el guardia de permisos después de establecer el valor por defecto
    guardMyRoute(to, from, next);
  }
}

function guardMyRoute(to, from, next) {
  const currentPermissions = store.getters.getPermissionProjects;
  const isAuthenticated = store.getters.isAuthenticated;
  const currentUser = store.getters.getCurrentUser;
  const menu = store.getters.getMenu;
  let currentMenu = null
  for (let m of menu){
    currentMenu = findNodeByAlias(m, to.name);
    console.log(currentMenu)
    if (currentMenu) {
      break;
    }
  }
  
  console.log(currentMenu)
  console.log(to)

  if (isAuthenticated) {
    console.log(to.meta.requiresSystemAdmin)
    if (to.meta.requiresSystemAdmin) {
      if (currentUser.admin) {
        next();
      } else {
        Vue.$toast.warning(i18n.t('general.invalid_route'),);
        next({ name: 'Home' });
      }
    } else {
      console.log(to.meta.permission)
      console.log(currentPermissions)
      if (to.meta.permission) {
        const permission = to.meta.permission;
        const hasPermission = currentPermissions.some(element => permission.includes(element));
        console.log(hasPermission)
        if (hasPermission) {
          console.log('has permission')
          next();
        } else {
          Vue.$toast.warning(i18n.t('general.invalid_route'),);
          next({ name: 'Home' });
        }
      } else {
        next();
      }
    }
  }
}

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: Layout,
      children: [{
        path: '',
        component: Body,
        children: [
          /* { 
            path: '/:catchAll(.*)*', 
            name: 'NotFound', 
            component: NotFound 
          }, */
          {
            path: '',
            name: 'Home',
            component: Home,
          },
          {
            path: '/modi',
            name: 'ModiList',
            component: Modi,
            beforeEnter: guardMyRoute,
            meta: {
              requiresAuth: true,
              requiresSystemAdmin: false,
              permission: [PERMISSIONS.ADMIN, PERMISSIONS.READ]
            }
          },
          {
            path: '/npyms',
            name: 'NPYMS',
            component: NPYMS,
            beforeEnter: guardMyRoute,
            meta: {
              requiresAuth: true,
              requiresSystemAdmin: false,
              permission:  [PERMISSIONS.ADMIN, PERMISSIONS.READ]
            }
          },
          {
            path: '/modi/create/:id?',
            name: 'ModiDevices',
            props: true,
            component: ModiDevices,
            beforeEnter: guardMyRoute,
            meta: {
              requiresAuth: true,
              requiresSystemAdmin: true,
              permission:  [PERMISSIONS.ADMIN, PERMISSIONS.READ]
            }
          },
          {
            path: '/modi/graph/:id/:modi',
            name: 'ModiGraph',
            component: ModiGraph,
            beforeEnter: guardMyRoute,
            meta: {
              requiresAuth: true,
              requiresSystemAdmin: false,
              permission: [PERMISSIONS.ADMIN, PERMISSIONS.READ]
            }
          },
          {
            path: '/config',
            name: 'ConfigView',
            component: ConfigView,
            beforeEnter: guardMyRoute,
            meta: {
              requiresAuth: true,
              requiresSystemAdmin: false,
              permission: [PERMISSIONS.ADMIN, PERMISSIONS.READ]
            }
          },
          {
            path: '/config/users',
            name: 'UserList',
            component: UsersList,
            beforeEnter: guardMyRoute,
            meta: {
              requiresAuth: true,
              requiresSystemAdmin: true,
              permission: [PERMISSIONS.ADMIN]
            }
          },
          {
            path: '/config/project',
            name: 'ProjectList',
            component: ProjectList,
            beforeEnter: guardMyRoute,
            meta: {
              requiresAuth: true,
              requiresSystemAdmin: false,
              permission: [PERMISSIONS.ADMIN]
            }
          },
          {
            path: '/config/project/create',
            name: 'ProjectCreate',
            component: ProjectCreate,
            beforeEnter: guardMyRoute,
            meta: {
              requiresAuth: true,
              requiresSystemAdmin: false,
              permission: [PERMISSIONS.ADMIN]
            }
          },
          {
            path: '/config/project/update/:id',
            name: 'ProjectUpdate',
            props: true,
            component: ProjectUpdate,
            beforeEnter: guardMyRoute,
            meta: {
              requiresAuth: true,
              requiresSystemAdmin: false,
              permission: [PERMISSIONS.ADMIN]
            }
          },
          {
            path: '/config/project/user/:projectId',
            name: 'ProjectUserList',
            props: true,
            component: UsersList,
            beforeEnter: guardMyRoute,
            meta: {
              requiresAuth: true,
              requiresSystemAdmin: false,
              permission: [PERMISSIONS.ADMIN]
            }
          },
          {
            path: '/config/project/user/:projectId/menu/:userId',
            name: 'UserMenuEdit',
            props: true,
            component: UserMenuEdit,
            beforeEnter: guardMyRoute,
            meta: {
              requiresAuth: true,
              requiresSystemAdmin: false,
              permission: [PERMISSIONS.ADMIN]
            }
          },
          {
            path: '/config/notifications',
            name: 'NotificationsConfig',
            component: NotificationsConfig,
            beforeEnter: guardMyRoute,
            meta: {
              requiresAuth: true,
              requiresSystemAdmin: true,
              permission: [PERMISSIONS.ADMIN]
            }
          },
          {
            path: '/forgot',
            name: 'Forgot',
            component: ChangePass,
            
          },
          /* {
            path: '/project',
            name: 'Project',
            component: PWBIProject,
          },
          {
            path: '/project/:urip/:projName',
            name: 'Boards',
            component: PWBI,
          }, */
          {
            path: '/board/:label/:urir',
            name: BOARD_ALIAS,
            props: true,
            component: PWBIBoard,
            beforeEnter: guardMyRoute,
            meta: {
              requiresAuth: true,
              requiresSystemAdmin: false,
              permission: [PERMISSIONS.ADMIN, PERMISSIONS.READ]
            }

          },
          {
            path: '/files/:type',
            name: 'FilesContainer',
            component: FilesContainer,
            beforeEnter: withDefaultTypeAndGuard,
            meta: {
              requiresAuth: true,
              requiresSystemAdmin: false,
              permission: [PERMISSIONS.ADMIN, PERMISSIONS.READ]
            }
          },
          {
            path: '/reports',
            name: 'Reports',
            component: FilesDownloader,
            beforeEnter: guardMyRoute,
            meta: {
              requiresAuth: true,
              requiresSystemAdmin: false,
              permission: [PERMISSIONS.ADMIN, PERMISSIONS.READ]
            }
          }
        ]
      }]
    }]
})
